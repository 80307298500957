import Swal from "sweetalert2";
import Vue from 'vue'
import {utils} from "./utils";
import {responses} from "./responses";
import {errorHandler} from "./errorHandler";
import queryStringify from "qs-stringify";


window.backOfficeTomcatApi = `https://realtime.abc.co.zm:8443/Lyt-SoftBackOfficeApi/`
// window.backOfficeTomcatApi = `http://localhost:8080/Lyt-SoftBackOfficeApi/`

window.franchiseTomcatApi = `https://overflow.abc.co.zm:8443/Franchise_OnlineServer/`
//window.franchiseTomcatApi = `https://realtime.abc.co.zm:8443/Franchise_OnlineServer/`
// window.franchiseTomcatApi = `http://192.168.1.228:8080/Franchise_OnlineServer/`

window.wareHouseTomcatApi = `https://lna.abc.co.zm/WarehouseManager/`
// window.wareHouseTomcatApiC = `http://192.168.0.141:8181/warehouse/`

window.navTomcatApi = wareHouseTomcatApi+`nav/`
// window.navTomcatApi = `http://192.168.1.141:8080/Franchise_OnlineServer/nav/`

window.responses = responses;
window.errorHandler = errorHandler

const EventBus = new Vue()
window.EventBus = EventBus

window.log = (val) => console.log(val);
window.Swal = Swal;

window.generateUUID = () => {
    let uuid = '', i, random;
    for (i = 0; i < 32; i++) {
        random = Math.random() * 16 | 0;
        if (i === 8 || i === 12 || i === 16 || i === 20) {
            uuid += '-';
        }
        uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
    }
    return uuid;
}

Vue.prototype.$utils = utils;
window.$utils = utils;

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1500,
    showCloseButton: true,
    customClass: 'toastr',
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
window.Toast = Toast;
window.stringify = (object) => queryStringify(object);
window.openUrl = (url,target = '_blank') => window.open(url,target);