import { Storage as Store } from '@capacitor/storage';

export const DEVICE_ID = 'deviceID';
export const MOBILE_USER = 'mobileUser';
export const ALL_STORES = 'allStores';
export const CURRENT_STORE = 'currentStore';

// Users
export const ALL_USERS = 'allUsers';
export const CURRENT_USER = 'currentUser';

const encryptAdmin = (val) => {
    return window.btoa(val);
};

const decryptAdmin = (val) => {
    if (!val) return undefined;
    if (val.toString().charAt(0) === `"`) {
        val = val.toString().slice(1, -1);
    }
    return JSON.parse(window.atob(val));
};

const setValue = (key, value) => Store.set({ key: key, value: JSON.stringify(value) });

const getValue = async (key) => {
    const value = await Store.get({ key: key });
    return JSON.parse(value.value);
};

export const Storage = {
    setMobileUser: async (payload) => {
        let value = encryptAdmin(JSON.stringify(payload));
        return await setValue(MOBILE_USER, value);
    },
    getMobileUser: async () => {
        const value = await Store.get({ key: MOBILE_USER });
        return decryptAdmin(value.value);
    },

    setAllStores: async (payload) => setValue(ALL_STORES, payload),
    getAllStores: async () => {
        return await getValue(ALL_STORES);
    },

    setCurrentStore: async (payload) => setValue(CURRENT_STORE, payload),
    getCurrentStore: async () => {
        return await getValue(CURRENT_STORE);
    },
    getCurrentLocationID: async () => {
        let store = await getValue(CURRENT_STORE);
        if (!store) return null;
        return store.id;
    },

    // Users
    setCurrentUser: async (payload) => setValue(CURRENT_USER, payload),
    getCurrentUser: async () => getValue(CURRENT_USER),

    setAllUsers: async (payload) => setValue(ALL_USERS, payload),
    getAllUsers: async () => getValue(ALL_USERS),

    // VAT & Tax
    setTaxLabels: async (payload) => setValue(TAX_LABELS, payload),
    getAllTaxLabels: async () => getValue(TAX_LABELS),

    // Device ID
    setDeviceID: async (payload) => setValue(DEVICE_ID, payload),
    getDeviceID: async () => {
        let deviceID = await getValue(DEVICE_ID);
        if (!deviceID) {
            deviceID = generateUUID();
            await setValue(DEVICE_ID, deviceID);
        }
        return deviceID;
    },

    // Clear the current user from storage
    clearUser: async () => {
        await Store.remove({ key: CURRENT_USER });
    },
};
