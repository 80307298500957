<<template>
  <div class="drawer" v-if="curUser">
    <div class="user-avatar">
      <div v-if="curUser" class="avatar-details">
        <h5>{{ curUser.Username }}</h5>
        <p>{{ curUser.Type }}</p>
        <p>{{ defaultStore }}</p>
      </div>
    </div>
    <div class="routes">
      <ul>
        <li v-if="isUpdateAvailable">
          <a @click="updateApp">
            <div class="icon-box">
              <font-awesome-icon class="icon text-info" icon="fa-solid fa-exclamation-circle"/>
            </div>
            <div class="d-flex flex-column text-center">
              Update Is Available
              <span class="text-sm">Click to Update</span>
            </div>
          </a>
        </li>

        <li>
          <router-link @click.native="back" :to="{ name: 'pending-orders' }">
            <div class="icon-box">
              <font-awesome-icon class="icon" icon="fa-solid fa-basket-shopping"/>
            </div>
            View Pending Orders
          </router-link>
        </li>

        <li>
          <div class="icon-box" @click="logout">
            <font-awesome-icon class="icon" icon="fa-solid fa-arrow-right-from-bracket"/>
            Log out
          </div>

        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Storage } from '@/Services/Storage'
import Swal from 'sweetalert2'

export default {
  name: "NavigationDrawer",
  props: {
    close: {
      type: Function,
      required: true,
    }
  },
  computed: {
    defaultStore() {
      let store = Storage.getCurrentStore();
      if (!store) this.$router.push({ name: 'userLogin' });
      return store.location;
    },
    curUser() {
      let user = Storage.getCurrentUser();
      if (!user) this.$router.push({ name: 'userLogin' });
      return user;
    },
    isUpdateAvailable() {
      return this.$store.getters.getUpdateAvailability;
    },
  },
  methods: {
    back() {
      if (this.close) {
        this.close();
      } else {
        console.log("Close not defined");
      }
    },
    logout() {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to Logout?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Logout"
      }).then((result) => {
        if (result.isConfirmed) {
          // Clear user data and redirect to login
          Storage.clearUser();
          this.$router.push({ name: 'userLogin' });

          // Show success message
          Swal.fire({
            title: "Logged Out!",
            text: "You have been Logged out successfully.",
            icon: "success"
          });
        }
      });
    },
    updateApp() {
      this.back();
      this.$store.commit('setUpdateAvailability', false);
      window.location.reload(true);
    },
  }
}

</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  display: none;
}

.drawer {
  width: 100%;
  background: white;
  height: 100%;
  overflow-y: auto;

  .routes {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0;

        a {
          height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 600;
          padding-left: 10px;
          text-decoration: none;
          color: #1c1c1c;

          .icon-box {
            width: 35px;
            margin-right: 10px;
            display: flex;
            justify-content: center;

            .icon {
              font-size: 25px;
              color: #34373C;
            }
          }

          &:hover, &.router-link-exact-active {
            background: #f1f1f1;
            color: #3F8DCD;
            font-weight: 700;

            .icon-box {
              .icon {
                color: #3F8DCD;
              }
            }
          }
        }
      }
    }
  }

  .user-avatar {
    display: inline-flex;
    width: 100%;
    background: url('/src/assets/img/play-banner.png') fixed;
    background-size: cover;
    color: white;
    min-height: 140px;
    background-color: rgba(50, 70, 80, 0.7);
    background-blend-mode: soft-light;

    .avatar {
      background: #40434A;
      min-width: 80px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .avatar-details {
      width: 100%;
      padding: 5px 5px 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      h5, p {
        margin: 0;
      }

      p {
        font-size: 15px;
        color: #ffffff;
        font-weight: 400;

        &:nth-child(3) {
          color: #15356E;
          font-weight: 600;
        }
      }

    }
  }
}

ul {
  text-align: center;

  span {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: bold;
  }
}

.last-upd {
  font-weight: bold;
}
</style>