import axios from "axios";
import {Storage} from "@/Services/Storage";

export default {
    name: 'orders',
    state: {
        itemsToScan: [],
        scannedItems: []
    },
    getters: {
        getItemsToScan: (state) => state.itemsToScan,
        getScannedItems: (state) => state.scannedItems,
    },
    mutations: {
        setItemsToScan: (state, payload) => {
            state.itemsToScan = payload;
        },

        setScannedItems: (state, payload) => {
            state.scannedItems = payload;
        },
        addToScannedItems: (state, payload) => {
            let id = payload.plu;
            let notScanned = state.itemsToScan.find(notScanned => notScanned.plu.toLowerCase() === id.toLowerCase());
            if (!notScanned) {
                this.playBuzzer();
                return responses.showWarning(`Item not Found !!`);
            }
            if (notScanned.qty > 1) {
                notScanned.qty--;
            } else {
                state.itemsToScan = state.itemsToScan.filter(item => item.plu.toLowerCase() !== id.toLowerCase());
            }
            let item = state.scannedItems.find(item => item.plu.toLowerCase() === id.toLowerCase());
            if (item) {
                if (item.requiredQty < ((item.qty) + 1))

                    item.qty = Number(item.qty) + 1;
            } else {
                state.scannedItems.push({
                    id: payload.id,
                    plu: payload.plu,
                    barcode: payload.barcode,
                    description: payload.description,
                    unit_of_measure: payload.unit_of_measure,
                    qty: 1,
                    pickingID: payload.pickingID
                })
            }
        },
    },
    actions: {
        getItemsByOrderNumber: async (context, orderNumber) => {
            let user = await Storage.getCurrentUser();
            user = user.id;
            return axios.get(navTomcatApi + `checkout/open?id=${orderNumber}&User=${user}`)
                .then(({data}) => context.commit(`setItemsToScan`, data.map(item => {
                    item.requiredQty = item.qty
                    return item
                }),

            Number(item.qty > 0)));
        },
        finalizeOrder: async (context, orderNumber) => {
            let user = await Storage.getCurrentUser();
            user = user.id;
            let obj = context.getters.getScannedItems;
            return axios.post(navTomcatApi + `checkout/completed?id=${orderNumber}&user=${user}`, obj)
                .then(() => {
                    context.commit(`setItemsToScan`, []);
                    context.commit(`setScannedItems`, []);
                }).catch(err => errorHandler.tomcatError(err))
        },
        getAllOrders: async () => {
            let location = await Storage.getCurrentLocationID();
            return axios.get(navTomcatApi + `checkout/getAll?offset=0&limit=1000&location=` + location)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        }

    }
}
